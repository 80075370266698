import React from "react"
import { Link } from "gatsby"

import { IconContext } from "react-icons"
import { FaFacebookSquare, FaInstagram, FaTwitter } from "react-icons/fa"

import footerStyles from "../styles/components/footer.module.css"

const Footer = () => (
  <footer>
    <div className={ footerStyles.ourLocation }>
      <h5>Our Office</h5>
      <p><span>ED IMC FIRM</span></p>
      <p>The IMC Block</p>
      <p>141 Scout Rallos Extension</p>
      <p>Sacred Heart, Quezon City</p>
      <div className={ footerStyles.phoneNumbers }>
          <p>Trunk Lines:</p>
          <p>(02) 8925-4761</p>
          <p>(02) 8426-4844</p>
          <p>(02) 3413-6436</p>
          <p>(02) 3448-5386</p>
      </div>
    </div>
    <div className={ footerStyles.footerNav }>
      <h5>Navigation</h5>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about/">About</Link></li>
        <li><Link to="/services/">SubUnits</Link></li>
        <li><Link to="/faq/">FAQ</Link></li>
        <li><Link to="/news/">News</Link></li>
        <li><Link to="/contact/">Contact Us</Link></li>
      </ul>
    </div>
    <div className={ footerStyles.subUnits }>
      <h5>SubUnits</h5>
      <ul>
        <li><a href="http://tedevents.com.ph/">TED Events</a></li>
        <li><a href="http://teamplay.com.ph/">TEAMPLAY</a></li>
        <li><a href="http://truenortharthouse.com.ph/">TrueNorth ArtHouse</a></li>
        <li><a href="http://creativecaboose.com.ph/">Creative Caboose</a></li>
        <li><a href="http://flair.com.ph/">Flair!</a></li>
      </ul>
    </div>
    <div className={ footerStyles.socialMedia }>
      <ul>
        <li>
          <a href="https://www.facebook.com/Executive-Decisions-An-Integrated-Marketing-Communications-Firm-130054157116180/">
            <IconContext.Provider value={{ size: "1.25em", color: "#1A4125" }}>
                <FaFacebookSquare />
            </IconContext.Provider>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/edimcf">
            <IconContext.Provider value={{ size: "1.25em", color: "#1A4125" }}>
                <FaTwitter />
            </IconContext.Provider>
          </a>
        </li>
        <li>
            <a href="https://www.instagram.com/edimcf/">
                <IconContext.Provider value={{ size: "1.25em", color: "#1A4125" }}>
                    <FaInstagram />
                </IconContext.Provider>
            </a>
        </li>
      </ul>
    </div>
    <p className={ footerStyles.copyrightText }>
      Copyright © 2020
    </p>
  </footer>
)

export default Footer